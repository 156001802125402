<template>
  <DefaultTemplate
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <div class="box-name-page">
        <p class="title">Email Notification</p>
        <div class="is-flex ai-center">
          <el-button
            type="primary"
            @click="dialogAddEmail = true"
            v-if="checkPermissionMore()"
            class="min-100"
          >
            เพิ่มอีเมล
          </el-button>
        </div>
      </div>
      <el-row :gutter="20">
        <el-form label-position="top" class="set-form">
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ค้นหา">
              <el-input
                id="searchEmail"
                placeholder="ค้นหาอีเมล"
                v-model="searchData"
                clearable
                class="search"
                @input="searchEmail(600)"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div
      class="box-card mg-t-5 animate__animated animate__fadeIn animate__slow"
    >
      <el-row :gutter="20">
        <el-form label-position="top" class="set-form">
          <el-col :span="24">
            <el-table
              class="
                table-normal
                animate__animated animate__fadeIn animate__slow
              "
              :data="tableData"
              style="width: 100%"
              v-loading="loading"
              element-loading-text="กำลังโหลดข้อมูล..."
              element-loading-spinner="el-icon-loading"
            >
              <template slot="empty">
                <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
              </template>
              <el-table-column label="อีเมล" prop="email" min-width="170">
                <template slot-scope="scope">
                  <p class="mg-0 text-left">{{ scope.row.email }}</p>
                </template>
              </el-table-column>
              <el-table-column label="สถานะแจ้งเตือนอีเมล" min-width="170">
                <template slot-scope="scope">
                  <div class="is-flex ai-center js-center switch-email">
                    <el-switch
                      @change="setStatusEmail(scope.row)"
                      v-model="scope.row.status"
                      :active-value="1"
                      :inactive-value="2"
                      class="mg-r-6"
                    >
                    </el-switch>
                    <span v-if="scope.row.status == 1"
                      >เปิดการแจ้งเตือนอีเมล</span
                    >
                    <span v-else-if="scope.row.status == 2"
                      >ปิดการแจ้งเตือนอีเมล</span
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="แก้ไขอีเมล" width="80" v-if="checkPermissionMore()">
                <template slot-scope="scope">
                  <div class="is-flex js-center">
                    <el-button
                      @click="editEmailById(scope.row)"
                      class="btn-color2"
                    >
                      <i class="fas fa-edit"></i>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="ลบอีเมล" width="80" v-if="checkPermissionMore()"> 
                <template slot-scope="scope">
                  <div class="is-flex js-center">
                    <el-button
                      @click="deleteEmailById(scope.row)"
                      class="btn-color3"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="24">
            <div class="text-center mg-t-5">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="changePage"
                :current-page.sync="pageNow"
                :page-sizes="[10, 30, 50, 100, 500]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNow"
              >
              </el-pagination>
            </div>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <el-dialog
      :visible.sync="dialogAddEmail"
      width="35%"
      :before-close="closeAddEmail"
      class="modal-criterion"
    >
      <p class="text-center font-20">เพิ่มอีเมลในการแจ้งเตือน</p>
      <el-form
        @submit.native.prevent="submitAddEmail"
        class="set-form"
        label-position="top"
        :model="form"
        :rules="rules"
        ref="formEmailAdd"
      >
        <el-form-item label="อีเมล" prop="email" class="padding-less font-16">
          <el-input
            v-model="form.email"
            @blur="form.email = form.email.trim()"
            placeholder="example@gmail.com"
          ></el-input>
        </el-form-item>
        <div class="is-flex js-end mg-t-4">
          <el-button class="font-16 btn-cancle" @click="closeAddEmail"
            >ยกเลิก</el-button
          >
          <el-button
            type="primary"
            class="font-16"
            native-type="submit"
            :loading="loadingSave"
            style="min-width: 120px"
            >บันทึก</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogEditEmail"
      width="35%"
      :before-close="closeEditEmail"
      class="modal-criterion"
    >
      <p class="text-center font-20">แก้ไขอีเมลในการแจ้งเตือน</p>
      <el-form
        @submit.native.prevent="submitEditEmail"
        class="set-form"
        label-position="top"
        :model="form"
        :rules="rules"
        ref="formEmailEdit"
      >
        <el-form-item label="อีเมล" prop="email" class="padding-less font-16">
          <el-input
            v-model="form.email"
            @blur="form.email = form.email.trim()"
            placeholder="example@gmail.com"
          ></el-input>
        </el-form-item>
        <div class="is-flex js-end mg-t-4">
          <el-button class="font-16 btn-cancle" @click="closeEditEmail"
            >ยกเลิก</el-button
          >
          <el-button
            type="primary"
            class="font-16"
            native-type="submit"
            :loading="loadingSave"
            style="min-width: 120px"
            >บันทึก</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      class="dialog-custom admin"
      title="ยืนยันการลบอีเมล"
      v-if="dialogDeleteEmail"
      :visible.sync="dialogDeleteEmail"
      width="70%"
      top="10vh"
      center
    >
      <div>
        <p class="mg-0 text-center mg-b-4">
          <span>
            คุณต้องการที่จะลบอีเมล
            <span class="color-light-blue"> {{ form.email }}</span>
            หรือไม่ ?
          </span>
        </p>
        <div class="is-flex js-end mg-t-4">
          <el-button
            id="cancleDeleteEmail"
            class="font-16 btn-cancle min-100"
            @click="dialogDeleteEmail = false"
          >
            ยกเลิก</el-button
          >
          <el-button
            id="submitDeleteEmail"
            type="primary"
            class="font-16"
            native-type="submit"
            :loading="loadingSave"
            style="min-width: 120px"
            @click="submitDeleteEmail(form.id)"
            >ยืนยัน</el-button
          >
        </div>
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
import moment from "moment";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
  },
  data() {
    return {
      searchData: "",
      totalNow: 10,
      limit: 10,
      pageNow: 1,
      tableData: [],
      debounce: null,
      dialogAddEmail: false,
      dialogEditEmail: false,
      dialogDeleteEmail: false,
      loading: true,
      loadingData: true,
      loadingSave: false,
      form: {
        email: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur",
          },
          {
            type: "email",
            message: "กรุณากรอกอีเมลให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      permissionNow: (state) => state.permissionNow,
    }),
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "email-notification");
    this.getEmailNoti();
  },
  methods: {
    closeAddEmail() {
      this.dialogAddEmail = false;
      this.form = {
        email: "",
      };
      this.$refs["formEmailAdd"].resetFields();
    },
    closeEditEmail() {
      this.dialogEditEmail = false;
      this.form = {
        email: "",
      };
      this.$refs["formEmailEdit"].resetFields();
    },
    submitAddEmail() {
      this.$refs["formEmailAdd"].validate().then((valid) => {
        if (valid) {
          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          HTTP.post(`ch/notification`, this.form)
            .then(async (res) => {
              if (res.data.success) {
                this.$message({
                  message: "เพิ่มอีเมล สำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.closeAddEmail();
                this.pageNow = 1;
                this.loading = true;
                this.getEmailNoti();
                let debounce = null;
                clearTimeout(debounce);
                debounce = setTimeout(() => {
                  this.loadingSave = false;
                }, 600);
              } else {
                this.loadingSave = false;
                if (res.data.status == 204) {
                  this.$message({
                    message: "ชื่ออีเมลซ้ำ",
                    type: "error",
                    duration: 4000,
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 422) {
                this.$message({
                  message: "กรุณากรอกอีเมลให้ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },
    submitEditEmail() {
      this.$refs["formEmailEdit"].validate().then((valid) => {
        if (valid) {
          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          HTTP.put(`ch/notification/${this.form.id}`, {
            email: this.form.email,
          })
            .then(async (res) => {
              if (res.data.success) {
                this.$message({
                  message: "แก้ไขอีเมล สำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.closeEditEmail();
                this.pageNow = 1;
                this.loading = true;
                this.getEmailNoti();
                let debounce = null;
                clearTimeout(debounce);
                debounce = setTimeout(() => {
                  this.loadingSave = false;
                }, 600);
              } else {
                this.loadingSave = false;
                if (res.data.status == 204) {
                  this.$message({
                    message: "ชื่ออีเมลซ้ำ",
                    type: "error",
                    duration: 4000,
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 422) {
                this.$message({
                  message: "กรุณากรอกอีเมลให้ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },
    submitDeleteEmail() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.delete(`ch/notification/${this.form.id}`)
        .then(async (res) => {
          if (res.data.success) {
            this.$message({
              message: "ลบอีเมล สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.dialogDeleteEmail = false;
            this.form = {
              email: "",
            };
            this.pageNow = 1;
            this.loading = true;
            this.getEmailNoti();
            let debounce = null;
            clearTimeout(debounce);
            debounce = setTimeout(() => {
              this.loadingSave = false;
            }, 600);
          } else {
            this.loadingSave = false;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    setStatusEmail(form) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.put(`ch/notification/change/${form.id}`, {
        status: form.status,
      })
        .then(async (res) => {
          if (res.data.success) {
            this.$message({
              message:
                form.status == 1
                  ? "เปิดการแจ้งเตือนอีเมล สำเร็จ"
                  : "ปิดการแจ้งเตือนอีเมล สำเร็จ",
              type: "success",
              duration: 4000,
            });
          } else {
            this.loadingSave = false;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    editEmailById(row) {
      this.dialogEditEmail = true;
      this.form = { ...row };
    },
    deleteEmailById(row) {
      this.dialogDeleteEmail = true;
      this.form = { ...row };
    },
    checkPermissionMore() {
      let ifPermission = this.permissionNow.some(
        (e) => e.pemNum == this.$route.meta.permission && e.status == 2
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    searchEmail(time = 0) {
      this.pageNow = 1;
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getEmailNoti();
      }, time);
    },
    async getEmailNoti() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.get(
        `ch/notification?page=${this.pageNow}&limit=${this.limit}&key=${this.searchData}`
      )
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.obj.data;
            this.tableData.sort((a, b) => {
              let modifier = 1;
              if (a.status < b.status) return -1 * modifier;
              if (a.status > b.status) return 1 * modifier;
              return 0;
            });
            this.totalNow = res.data.obj.totalPage;
            this.loading = false;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }

          this.loadingData = false;

          return res;
        })
        .catch((e) => {
          this.loadingData = false;
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad Gateway");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        });
      return res;
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.pageNow = page;
      this.loading = true;
      setTimeout(() => {
        this.getEmailNoti();
      }, 600);
    },
  },
};
</script>
